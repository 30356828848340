import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeToken } from "../store/actions/authActions"; // Ajuste o caminho conforme necessário

const AuthCheck = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica a data do último login
    const lastLoginDate = localStorage.getItem("lastLoginDate");
    const currentDate = new Date().toISOString().split('T')[0]; // Apenas a data no formato YYYY-MM-DD

    if (!lastLoginDate || lastLoginDate.split('T')[0] !== currentDate) {
      // Se não há data de login ou a data não é a mesma do dia atual, força o logout
      dispatch(removeToken());
      // Redireciona para a página de login
      navigate("/login");
    }
  }, [dispatch, navigate]);

  return <>{children}</>;
};

export default AuthCheck;
