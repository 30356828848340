import React from 'react';
import { Button } from './style';

const CustomButton = ({ children, onClick, bgColor, color, type, ...props }) => {
  return (
    <Button onClick={onClick} bgColor={bgColor} color={color} type={type} {...props}>
      {children}
    </Button>
  );
};

export default CustomButton;
