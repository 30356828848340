import { Box, TextField, Typography, Grid, CircularProgress, Autocomplete } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import CustomButton from '../../components/Button/CustomButon';
import { useDebounce } from '../../hooks/UseDebounce';
import UsersService from '../../services/UsersService';
import { NumericFormat } from 'react-number-format';
import { parse, format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';

const schema = yup.object({
    descricao: yup.string().required(),
    fim_contrato: yup.date().required(),
    inicio_contrato: yup.date().required(),
    valor: yup.number().required(),
});

function EditContrato({ data, onClose }) {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: data
    });
    const dispatch = useDispatch();
    const { debounce } = useDebounce(300);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (data) {
            setValue("nomeCliente", data.nomeCliente);
            setValue("tipo_documentacao_id", data.tipo_documento_id);
            setValue("descricao", data.descricao);

            // Converte as datas para o formato "yyyy-MM-dd"
            const fimContrato = format(parse(data.fim_contrato, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
            const inicioContrato = format(parse(data.inicio_contrato, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');

            setValue("fim_contrato", fimContrato);
            setValue("inicio_contrato", inicioContrato);
            setValue("valor", data.valor);

            // Define a opção selecionada para o Autocomplete
            setSelectedOption({ id: data.tipo_documento_id, nome: data.tipo_documento });
        }
    }, [data, setValue]);

    const onSubmit = (formData) => {
        // Converte as datas para o formato "yyyy-MM-dd"
        const fimContrato = format(new Date(formData.fim_contrato), 'yyyy-MM-dd');
        const inicioContrato = format(new Date(formData.inicio_contrato), 'yyyy-MM-dd');

        // Prepare os dados para serem enviados
        const payload = {
            id: formData.id,
            descricao: formData.descricao,
            fim_contrato: fimContrato,
            inicio_contrato: inicioContrato,
            tipo_documentacao_id: formData.tipo_documentacao_id,
            valor: formData.valor,
        };

        dispatch(changeloading({ open: true, msg: 'Editando...' }));
        UsersService.update(payload.id, payload, 'contrato').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            onClose();
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };

    const buscaDocumento = useCallback(async (query) => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: query }, 'trazer/tipo');
        setOptions(res.tipo || []);
        setLoading(false);
    }, []);

    useEffect(() => {
        debounce(() => {
            if (inputValue.trim() !== '') {
                buscaDocumento(inputValue);
            } else {
                setOptions([]);
            }
        });
    }, [inputValue, debounce, buscaDocumento]);

    useEffect(() => {
        if (open) {
            buscaDocumento('');
        }
    }, [open]);

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                            fullWidth
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            getOptionLabel={(option) => option.nome}
                            options={options}
                            loading={loading}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            value={selectedOption}
                            onChange={(event, newValue) => {
                                setSelectedOption(newValue);
                                setValue("tipo_documentacao_id", newValue ? newValue.id : '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo"
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Inicio Contrato'
                            type='date'
                            variant='outlined'
                            fullWidth
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            {...register("inicio_contrato")}
                        />
                        <Typography variant='subtitle2'>{errors?.inicio_contrato?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Fim Contrato'
                            type='date'
                            variant='outlined'
                            fullWidth
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            {...register("fim_contrato")}
                        />
                        <Typography variant='subtitle2'>{errors?.fim_contrato?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Descrição'
                            type='text'
                            variant='outlined'
                            fullWidth
                            size='small'
                            multiline
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { resize: 'vertical' } }}
                            {...register("descricao")}
                        />
                        <Typography variant='subtitle2'>{errors?.descricao?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <NumericFormat
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            allowedDecimalSeparators={["."]}
                            prefix="R$ "
                            thousandSeparator="."
                            customInput={TextField}
                            size="small"
                            label="Valor"
                            isAllowed={(values) => {
                                if (values.value.length > 9) return false;
                                return true;
                            }}
                            {...register("valor")}
                            value={data.valor} // Define o valor inicial
                            onValueChange={(values) => {
                                setValue('valor', values.value); // Atualiza o valor no formulário
                            }}
                        />
                        <Typography variant='subtitle2' color="error">{errors?.valor?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomButton type='submit'>Salvar</CustomButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default EditContrato;
