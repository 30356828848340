export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80vh', // Define uma altura máxima para o modal
 
  boxShadow: 24,
  p: 4,
  bgcolor: '#e0e0e0',
  overflow: 'auto' // Adiciona rolagem quando o conteúdo excede o tamanho
};

export const modalStyleInput = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  maxHeight: '80vh', // Define uma altura máxima para o modal  
  boxShadow: 24,
  p: 4,
  bgcolor: '#e0e0e0',
  overflow: 'auto' // Adiciona rolagem quando o conteúdo excede o tamanho
};

export const modalStylePesquisar = {
    position: 'absolute',
    top: '150px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',   
    boxShadow: 24,
    p: 4,
     bgcolor:'#e0e0e0'
  };

  export const modalStyleTipoContrato = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '340px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  export const statusStyles = {
    ativo: { color: 'green' },
    alerta: { color: '#e4c115' },
    vencido: { color: 'red' },
  };
  