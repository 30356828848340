import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Grid, Button, FormControl } from '@mui/material';
import { useForm } from "react-hook-form";
import { modalStyle } from "../../themes/stylesGlobal";
import { SelectAutoComplete } from '../../components'; // Importa o componente de seleção
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeNotify } from '../../store/actions/notify.actions';
import { changeloading } from '../../store/actions/loading.action';

function EditarTreinamento({ open, onClose, dadosTreinamento, onUpdate }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();

    const [selectedFuncionario, setSelectedFuncionario] = useState(null);
    const [selectedTreinamento, setSelectedTreinamento] = useState(null);

    useEffect(() => {
        if (dadosTreinamento) {
            setSelectedFuncionario({
                id: dadosTreinamento.funcionario_id,
                nome: dadosTreinamento.funcionario,
            });
            setSelectedTreinamento({
                id: dadosTreinamento.treinamento_id,
                nome: dadosTreinamento.treinamento,
            });
            setValue('data_inicio', dadosTreinamento.data_inicio);
            setValue('data_fim', dadosTreinamento.data_fim);
            setValue('duracao', dadosTreinamento.duracao);
            setValue('status', dadosTreinamento.status);
        }
    }, [dadosTreinamento, setValue]);

    const onSubmit = async (data) => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        try {
            const payload = {
                funcionario_id: selectedFuncionario?.id,
                treinamento_id: selectedTreinamento?.id,
                data_inicio: data.data_inicio,
                data_fim: data.data_fim,
                duracao: data.duracao,
                status: data.status,
            };
            const res = await UsersService.patch(dadosTreinamento.id, payload, 'controle/treinamento');
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            
            // Atualiza a tabela com os dados retornados
            if (onUpdate) {
                onUpdate(res.treinamento); // Supondo que a resposta tenha os dados atualizados do treinamento
            }

            onClose();
        } catch (error) { 
            console.log(error)           
            dispatch(changeNotify({
                open: true,
                class: 'error',
                msg: error.response.data.error || error.response.data.message ,
            }));
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-editar-treinamento" aria-describedby="modal-editar-treinamento-descricao">
            <Box sx={modalStyle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="funcionario"
                                    label="Nome do Colaborador"
                                    defaultValue={selectedFuncionario}
                                    onChange={(newValue) => setSelectedFuncionario(newValue)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="norma"
                                    label="Norma"
                                    defaultValue={selectedTreinamento}
                                    onChange={(newValue) => setSelectedTreinamento(newValue)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <TextField
                                label="Data Início"
                                type="date"
                                fullWidth
                                size='small'
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                {...register("data_inicio")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <TextField
                                label="Data Fim"
                                type="date"
                                fullWidth
                                variant="outlined"
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                {...register("data_fim")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <TextField
                                label="Duração"
                                fullWidth
                                size='small'
                                variant="outlined"
                                {...register("duracao")}
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <Button size='small' type="submit" variant="contained" color="primary" fullWidth>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}

export default EditarTreinamento;
