import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';


function SelectAno({ value, onChange }) {
  // Gera uma lista de anos de 2022 a 2026
  const anos = [2022, 2023, 2024, 2025, 2026];

  return (
    <FormControl fullWidth>
      <InputLabel>Ano</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        size='small'
        ful
      >
        {anos.map(ano => (
          <MenuItem key={ano} value={ano}>
            {ano}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectAno;
