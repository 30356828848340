import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm } from "react-hook-form";
import { modalStyle } from "../../themes/stylesGlobal";
import { SelectAutoComplete } from '../../components'; // Importa o componente de seleção
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeNotify } from '../../store/actions/notify.actions';
import { changeloading } from '../../store/actions/loading.action';

function EditarIntegracao({ open, onClose, dadosIntegracao, onUpdate }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();

    const [selectedFuncionario, setSelectedFuncionario] = useState(null);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);

    useEffect(() => {
        if (dadosIntegracao) {           
            setSelectedFuncionario({
                id: dadosIntegracao.funcionario_id,
                nome: dadosIntegracao.funcionario,
            });
            setSelectedEmpresa({
                id: dadosIntegracao.cliente_id,
                nome: dadosIntegracao.empresa,
            });
            // Aqui está a chave: atualizando o valor de status
            setValue('status', dadosIntegracao.status);
        }
    }, [dadosIntegracao, setValue]);

    const onSubmit = async (data) => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        try {
            const payload = {
                funcionario_id: selectedFuncionario?.id,
                cliente_id: selectedEmpresa?.id,
                status: data.status,
            };
            const res = await UsersService.patch(dadosIntegracao.id, payload, 'integracao');
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            
            // Atualiza a tabela com os dados retornados
            if (onUpdate) {
                onUpdate(res.integracao); // Supondo que a resposta tenha os dados atualizados da integração
            }

            onClose();
        } catch (error) { 
            console.log(error);          
            dispatch(changeNotify({
                open: true,
                class: 'error',
                msg: error.response.data.error || error.response.data.message,
            }));
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-editar-integracao" aria-describedby="modal-editar-integracao-descricao">
            <Box sx={modalStyle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="funcionario"
                                    label="Nome Colaborador"
                                    defaultValue={selectedFuncionario}
                                    onChange={(newValue) => setSelectedFuncionario(newValue)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="cliente"
                                    label="Empresa"
                                    defaultValue={selectedEmpresa}
                                    onChange={(newValue) => setSelectedEmpresa(newValue)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    variant='outlined'
                                    size='small'
                                    defaultValue={dadosIntegracao?.status || ''}
                                    {...register('status')}
                                >
                                    <MenuItem value="contratado">Contratado</MenuItem>
                                    <MenuItem value="demitido">Demitido</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Button size='small' type="submit" variant="contained" color="primary" fullWidth>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}

export default EditarIntegracao;
