import React, { useState, useContext } from 'react';
import { Box, Grid, TextField, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import UsersService from '../../../services/UsersService';
import { changeNotify } from '../../../store/actions/notify.actions';
import { Button } from '../../../components/Button/style';
import { LocationContext } from '../../../context/LocationContext';

const schema = yup.object({
    nome: yup.string().required('Nome é obrigatório'),

});

function CadastroFuncionario({onCadastroConcluido}) {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });
    const { estado, cidade, setIdEstado } = useContext(LocationContext);
    const [estadoSelecionado, setEstadoSelecionado] = useState('');
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangeEstado = (event) => {
        const estadoSelecionado = event.target.value;
        setEstadoSelecionado(estadoSelecionado);

        const estadoEncontrado = estado.find(e => e.sigla === estadoSelecionado);
        if (estadoEncontrado) {
            setIdEstado(estadoEncontrado.id);
        }
    };

    const onSubmitForm = (data) => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        UsersService.create(data, 'funcionario').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            onCadastroConcluido();
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };

    return (
        <Box bgcolor='#e0e0e0'>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Nome'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("nome")}
                        />
                        <Typography variant='subtitle2' color="error">{errors?.nome?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='CPF'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("cpf")}
                        />
                     
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Telefone'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("telefone")}
                        />
                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Email'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("email")}
                        />
                       
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Endereço'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("endereco")}
                        />
                     
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Complemento'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("complemento")}
                        />
                       
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Bairro'
                            variant='outlined'
                            fullWidth
                            size='small'
                            {...register("bairro")}
                        />
                       
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel>Selecione o estado</InputLabel>
                            <Select
                                variant='outlined'
                                size='small'
                                {...register('estado')}
                                value={estadoSelecionado}
                                onChange={handleChangeEstado}
                            >
                                {estado.map((estado) => (
                                    <MenuItem key={estado.id} value={estado.sigla}>
                                        {estado.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                       
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel>Selecione a cidade</InputLabel>
                            <Select
                                variant='outlined'
                                size='small'
                                {...register('cidade')}
                                value={cidadeSelecionada}
                                onChange={(e) => setCidadeSelecionada(e.target.value)}
                                disabled={!estadoSelecionado}
                            >
                                {cidade.map((cidade) => (
                                    <MenuItem key={cidade.id} value={cidade.nome}>
                                        {cidade.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <Button type='submit'>Salvar</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default CadastroFuncionario;
