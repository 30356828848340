import { Routes, Route, Navigate } from "react-router-dom";
import { CadastroCliente, Contrato, ControleMenu, Dashboard, Funcionarios, Integracao, Login,  Treinamento, TrocarSenha, Usuarios, } from "../pages";
import PrivateRouter from "./PrivateRouter";
import ControleDocumentacao from "../pages/ControleDocumentacao";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/trocarsenha" element={<TrocarSenha />} />
      <Route path="*" element={<Navigate to="/login" />} />

      {/* Rotas admin */}
      <Route path="/dashboard" element={<PrivateRouter>  <Dashboard /> </PrivateRouter>} />
      <Route path="/configuracao" element={<PrivateRouter>  <ControleMenu /> </PrivateRouter>} />
      {/* rotas cadastros */}
      <Route path="/cadastro" element={<PrivateRouter>  <CadastroCliente /> </PrivateRouter>} />    
      <Route path="/documentacao/:status?" element={<PrivateRouter>  <ControleDocumentacao /> </PrivateRouter>} />
      <Route path="/documentacao" element={<PrivateRouter>  <ControleDocumentacao /> </PrivateRouter>} />
      <Route path="/funcionarios" element={<PrivateRouter>  <Funcionarios /> </PrivateRouter>} />
      <Route path="/usuarios" element={<PrivateRouter>  <Usuarios /> </PrivateRouter>} />
      {/* rota contrato */}
      <Route path="/contratos" element={<PrivateRouter>  <Contrato /> </PrivateRouter>} />
      <Route path="/contratos/:status?" element={<PrivateRouter><Contrato /></PrivateRouter>} />
      {/*rotas  Integracao */}
      <Route path="/integracao" element={<PrivateRouter>  <Integracao /> </PrivateRouter>} />
      <Route path="/treinamento/:status?" element={<PrivateRouter>  <Treinamento /> </PrivateRouter>} />
    </Routes>
  );
};
