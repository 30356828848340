import { Box, TextField, InputAdornment, Modal, Button, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState, useCallback } from 'react';
import { Confirm, SelectAno, SelectMes, SelectStatus, TableComponet } from '../../components';
import UsersService from '../../services/UsersService';
import { useDebounce } from '../../hooks/UseDebounce';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import CustomButton from '../../components/Button/CustomButon';
import { modalStyle, modalStylePesquisar } from '../../themes/stylesGlobal';
import EditContrato from './EditContrato';

function TableContrato({ tableUpdateTriggerPai }) {
    const dispatch = useDispatch();
    const { status } = useParams();
    const [documento, setDocumento] = useState([]);
    const [totalPage, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
    const [busca, setBusca] = useState(status || '');
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState(null);

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    // modal pesquisar
    const [openPesquisar, setOpenPesquisar] = React.useState(false);
    const handleOpenPesquisar = () => setOpenPesquisar(true);
    const handleClosePesquisar = () => setOpenPesquisar(false);
    const [nomeEmpresa, setNomeEmpresa] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [fullDescriptions, setFullDescriptions] = useState({});


    const { debounce } = useDebounce(300);

    const fetchData = useCallback(async (page = 1) => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: busca }, 'contrato', page);
        setLoading(false);
        const formattedData = res.contrato.map(doc => ({
            ...doc,
            tipo_documento_id: doc.tipo_documento_id // Certifique-se de que o campo tipo_documento_id está presente
        }));
        setDocumento(formattedData || []);
        setTotalPages(res.totalPage || 0);
        setCurrentPage(page);
    }, [busca]);

    useEffect(() => {
        debounce(() => fetchData(currentPage));
    }, [busca, tableUpdateTrigger, tableUpdateTriggerPai, debounce, fetchData]);



    const handleEdit = (documento) => {
        // Extrai as propriedades necessárias do documento
        const { id, nomeCliente, inicio_contrato, fim_contrato, descricao, status, tipo_documento_id, tipo_documento, valor } = documento;

        // Cria um objeto editData com as propriedades necessárias
        const editDataWithId = {
            id,
            nomeCliente,
            inicio_contrato,
            fim_contrato,
            descricao: fullDescriptions[documento.id] || (typeof descricao === 'string' ? descricao : documento.descricao.props.children),
            status,
            tipo_documento_id,
            tipo_documento,
            valor
        };

        setEditData(editDataWithId);
        setOpenModal(true);
    };





    const handleCloseModal = () => {
        setOpenModal(false);
        setEditData(null);
        fetchData(currentPage); // Recarrega a tabela após fechar o modal
    };

    const handleOpenConfirmDialog = (id) => {
        let documentoId = id.id;
        setConfirmar({ documentoId, confirmDialogOpen: true });
    };

    const handleCloseConfirmDialog = () => {
        setConfirmar({ documentoId: null, confirmDialogOpen: false });
    };

    const [confirmar, setConfirmar] = useState({
        documentoId: null,
        confirmDialogOpen: false,
    });

    const handleDelete = () => {
        const idToDelete = confirmar.documentoId;
        UsersService.Delete(idToDelete, 'contrato')
            .then((res) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({
                    open: true,
                    class: 'success',
                    msg: res.message,
                }));
                setTableUpdateTrigger((prev) => prev + 1);
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(
                    changeNotify({
                        open: true,
                        class: 'error',
                        msg: error.message,
                    })
                );
            });
        handleCloseConfirmDialog();
    };

    const buscaDocumento = useCallback(async (query) => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: query }, 'trazer/tipo');
        setOptions(res.tipo || []);
        setLoading(false);
    }, []);

    useEffect(() => {
        debounce(() => {
            if (inputValue.trim() !== '') {
                buscaDocumento(inputValue);
            } else {
                setOptions([]);
            }
        });
    }, [inputValue, debounce, buscaDocumento]);

    useEffect(() => {
        if (open) {
            buscaDocumento('');
        } else {
            setOptions([]);
        }
    }, [open, buscaDocumento]);

    useEffect(() => {
        const descriptions = {};
        documento.forEach(doc => {
            descriptions[doc.id] = doc.descricao;
        });
        setFullDescriptions(descriptions);
    }, [documento]);


    const exportToExcel = (data) => {
        if (!data || data.length === 0) {
            alert("Nenhum dado para exportar");
            return;
        }
        // Mapear os dados para formatar as datas e remover colunas desnecessárias
        const formattedData = data.map(doc => ({
            ID: doc.id,
            'Nome do Cliente': doc.nomeCliente,
            'Data de Inicio': doc.inicio_contrato,
            'Data de Fim': doc.fim_contrato,
            'valor': doc.valor,
        }));

        // Cria a planilha a partir dos dados formatados
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Define a largura das colunas
        const columnWidths = [
            { wch: 10 }, // largura da coluna A
            { wch: 30 }, // largura da coluna B
            { wch: 30 }, // largura da coluna C
            { wch: 15 }, // largura da coluna D
            { wch: 15 }, // largura da coluna E
        ];

        worksheet['!cols'] = columnWidths;

        // Aplica estilo ao cabeçalho
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const address = XLSX.utils.encode_col(C) + "1";
            if (!worksheet[address]) continue;
            worksheet[address].s = { font: { bold: true } };
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Documentos');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Obtém a data e hora atual formatada
        const now = new Date();
        const formattedDate = format(now, 'dd/MM/yyyy');

        // Salva o arquivo Excel com a data e hora no nome
        saveAs(blob, `contratos${formattedDate}.xlsx`);
    };

    const buscarParaExcel = () => {
        dispatch(changeloading({ open: true, msg: 'carregando excel' }));
        // Monta os dados da requisição
        let data = {
            nomeEmpresa: nomeEmpresa,
            dataInicio: dataInicio,
            dataFim: dataFim,
            mesSelecionado: mesSelecionado,
            anoSelecionado: anoSelecionado,
            statusSelecionado: statusSelecionado
        };
        UsersService.getPesquisa(data, 'filtros/contrato')
            .then((res) => {
                if (res && res.contratos) {
                    exportToExcel(res.contratos);
                } else {
                    console.error("Nenhum dado recebido do servidor");
                    dispatch(changeNotify({ open: true, class: 'error', msg: 'Nenhum dado recebido do servidor' }));
                }
                dispatch(changeloading({ open: false }));
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({ open: true, class: 'error', msg: error.response?.data?.error || 'Erro desconhecido' }));
            });
    };

    const handleAtivo = async (documento) => {
        const updatedAtivo = !documento.ativo;

        try {
            await UsersService.update(documento.id, { ativo: updatedAtivo }, 'contrato/atualizar/contrato/ativo');
            dispatch(changeNotify({
                open: true,
                class: 'success',
                msg: 'Atualizado com sucesso',
            }));

            // Atualiza o estado localmente sem fazer outra requisição
            setDocumento((prevDocs) => prevDocs.map((doc) =>
                doc.id === documento.id ? { ...doc, ativo: updatedAtivo } : doc
            ));
        } catch (error) {
            dispatch(changeNotify({
                open: true,
                class: 'error',
                msg: error.response?.data?.error || 'Erro desconhecido',
            }));
        }
    };

    const filtrarPesquisa = () => {
        // Monta os dados da requisição
        let data = {
            nomeEmpresa: nomeEmpresa,
            dataInicio: dataInicio,
            dataFim: dataFim,
            mesSelecionado: mesSelecionado,
            anoSelecionado: anoSelecionado,
            statusSelecionado: statusSelecionado
        };

        UsersService.getPesquisa(data, 'filtros/contrato')
            .then((res) => {
                setDocumento(res.contratos || []);
                handleClosePesquisar();
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(
                    changeNotify({
                        open: true,
                        class: 'error',
                        msg: error.message,
                    })
                );
            });
    };

    const [openDescModal, setOpenDescModal] = useState(false);
    const [descContent, setDescContent] = useState('');

    const handleOpenDescModal = (descricao) => {
        setDescContent(descricao);
        setOpenDescModal(true);
    };

    const handleCloseDescModal = () => {
        setOpenDescModal(false);
        setDescContent('');
    };

    const headers = [
        {
            id: "nomeCliente",
            label: "Nome da Empresa",
            props: {
                align: 'left',
            },
        },
        {
            id: 'inicio_contrato',
            label: 'Data de Inicio',
            props: {
                align: 'right',
            },
        },
        {
            id: 'fim_contrato',
            label: 'Data Fim',
            props: {
                align: 'right',
            },
        },
        {
            id: 'descricao',
            label: 'Descrição',
            props: {
                align: 'right',
            },
        },
        {
            id: 'status',
            label: 'Status',
            props: {
                align: 'right',
            },
        },
        {
            id: 'actionRows',
            label: 'Ações',
            props: {
                align: 'right',
            },
        },
    ];

    return (
        <Box>
            <Box display='flex'>

                <Box width='40%'>
                    <TextField
                        label="Pesquisar"
                        value={busca}
                        onChange={(e) => setBusca(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            sx: {
                                lineHeight: '1.6', // ajusta a altura da linha para centralizar o texto verticalmente
                            },
                            inputProps: {
                                style: {
                                    padding: '10px 10px', // ajusta o padding interno do input para centralizar o texto
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                lineHeight: '1.6',
                            }
                        }}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                </Box>
                <Box marginTop='18px' marginLeft={4} width='150px'>
                    <Button size='small' sx={{ color: '#1a237e!important' }} onClick={handleOpenPesquisar}>Filtrar</Button>
                </Box>
            </Box>

            <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse documento?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
            />

            <TableComponet
                headers={headers}
                data={documento.map(doc => ({
                    ...doc,
                    descricao: (
                        <span onClick={() => handleOpenDescModal(doc.descricao)}>
                            {typeof doc.descricao === 'string' && doc.descricao.length > 10 ? `${doc.descricao.slice(0, 10)}...` : doc.descricao}
                        </span>
                    ),
                }))}
                labelCaption={'Nenhuma Documentação encontrada!'}
                labelTable={'Documentações'}
                handlerEditarAction={(documentoId) => { handleEdit(documentoId) }}
                handlerAtivoAction={(documento) => { handleAtivo(documento) }}
                handlerDeletarAction={(event) => { handleOpenConfirmDialog(event) }}
                request
                qdtPage={totalPage}
                loading={loading}
                setData={setDocumento}
                tableUpdateTrigger={tableUpdateTrigger}
                handlerRequest={async (page, size) => {
                    setLoading(true);
                    UsersService.getPagination({ search: busca }, 'contrato', page, size).then(
                        (data) => {
                            setLoading(false);
                            setDocumento(data.contrato || []);
                            setTotalPages(data.totalPages || 0);
                            return data;
                        },
                    );
                    return [];
                }}
            />

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <EditContrato data={editData} onClose={handleCloseModal} />
                </Box>
            </Modal>

            <Modal
                open={openPesquisar}
                onClose={handleClosePesquisar}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={modalStylePesquisar}>
                    <Grid container spacing={2}>

                        <Grid container spacing={1} marginLeft={1}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectAno value={anoSelecionado} onChange={(e) => setAnoSelecionado(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectMes value={mesSelecionado} onChange={(e) => setMesSelecionado(e.target.value)} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label='Nome da Empresa'
                                variant='outlined'
                                fullWidth
                                size='small'
                                value={nomeEmpresa}
                                onChange={(e) => setNomeEmpresa(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SelectStatus value={statusSelecionado} onChange={(e) => setStatusSelecionado(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label='Data de Inicio'
                                type='date'
                                variant='outlined'
                                fullWidth
                                size='small'
                                InputLabelProps={{ shrink: true, }}
                                value={dataInicio}
                                onChange={(e) => setDataInicio(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label='Data de Fim'
                                type='date'
                                variant='outlined'
                                fullWidth
                                size='small'
                                InputLabelProps={{ shrink: true, }}
                                value={dataFim}
                                onChange={(e) => setDataFim(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3}></Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CustomButton bgColor='#5e5e5e' onClick={buscarParaExcel}>Exportar excel</CustomButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CustomButton onClick={filtrarPesquisa}>Buscar</CustomButton>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={openDescModal}
                onClose={handleCloseDescModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="modal-modal-title">Descrição Completa</h2>
                    <p id="modal-modal-description">{descContent}</p>
                    <Button onClick={handleCloseDescModal}>Fechar</Button>
                </Box>
            </Modal>

        </Box>
    );
}

export default TableContrato;
