import { Box, TextField, InputAdornment, Modal} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import React, { useEffect, useState, useCallback } from 'react';
import { Confirm, TableComponet } from '../../components';
import UsersService from '../../services/UsersService';
import { useDebounce } from '../../hooks/UseDebounce';
import ClienteEmpresa from './ClienteEmpresa';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';


const headers = [
  {
    id: "nome_razao_social",
    label: "Nome",
    props: {
      align: 'left',
      style: { width: '200px' }
    },
  },
  {
    id: "cpf_cnpj",
    label: "Cpf/Cnpj",
    props: {
      align: 'right',
    },
  },
  {
    id: 'email',
    label: 'Email',
    props: {
      align: 'right',
    },
  },
  {
    id: 'telefone',
    label: 'Telefone',
    props: {
      align: 'right',
      style: { width: '150px' }
    },
  },
  {
    id: 'endereco',
    label: 'Endereço',
    props: {
      align: 'right',
      style: { width: '150px' }
    },
  },
  {
    id: 'complemento',
    label: 'Complemento',
    props: {
      align: 'right',
    },
  },
  {
    id: 'bairro',
    label: 'Bairro',
    props: {
      align: 'right',
    },
  },
  {
    id: 'cidade',
    label: 'Cidade',
    props: {
      align: 'right',
    },
  },
  {
    id: 'estado',
    label: 'Estado',
    props: {
      align: 'right',
    },
  },
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function TableCliente() {
  const dispatch = useDispatch();
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

  const [busca, setBusca] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const { debounce } = useDebounce(300);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await UsersService.getPagination({ search: busca }, 'cliente');
    setLoading(false);
    setClientes(res.clientes || []);
    setTotalPages(res.totalPage || 0);
  }, [busca]);



  useEffect(() => {
    debounce(fetchData);
  }, [busca, tableUpdateTrigger, debounce, fetchData]);

  const formatCpfCnpj = (cliente) => {
    return cliente.cpf || cliente.cnpj;
  };

  const formattedData = (Array.isArray(clientes) ? clientes : []).map(cliente => ({
    ...cliente,
    cpf_cnpj: formatCpfCnpj(cliente)
  }));

  const handleEdit = (clienteId) => {
    setSelectedCliente(clienteId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCliente(null);
  };
  const tableUpdateTriggerPai = () => {
    setTableUpdateTrigger((prev) => prev + 1);
  };

  //const do confirm
  const handleOpenConfirmDialog = (id) => {
    let clienteId = id.id

    setConfirmar({ clienteId, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ clienteId: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    clienteId: null,
    confirmDialogOpen: false,
  });

  const handleDelete = () => {
    const idToDelete = confirmar.clienteId;
    UsersService.Delete(idToDelete, 'cliente')
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({
          open: true,
          class: 'success',
          msg: res.message,
        })
        );
        setTableUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: 'error',
            msg: error.message,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  return (
    <Box>
      <Box width='40%'>
        <TextField
          label="Pesquisar"
          value={busca}
          onChange={(e) => setBusca(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              lineHeight: '1.6', // ajusta a altura da linha para centralizar o texto verticalmente
            },
            inputProps: {
              style: {
                padding: '10px 10px', // ajusta o padding interno do input para centralizar o texto
              },
            },
          }}
          InputLabelProps={{
            style: {
              lineHeight: '1.6',
            }
          }}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Box>
      <Confirm
        open={confirmar.confirmDialogOpen}
        title="Deseja realmente excluir esse cliente?"
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
      />
      <TableComponet
        headers={headers}
        data={formattedData}
        labelCaption={'Nenhum cliente encontrado!!'}
        labelTable={'Clientes'}
        handlerEditarAction={(clienteId) => { handleEdit(clienteId) }}
        handlerDeletarAction={(event) => { handleOpenConfirmDialog(event) }}
        request
        qdtPage={totalPage}
        loading={loading}
        setData={setClientes}
        tableUpdateTrigger={tableUpdateTrigger}
        handlerRequest={async (page, size) => {
          setLoading(true);
          UsersService.getPagination({ search: busca }, 'cliente', page, size).then(
            (data) => {
              setLoading(false)
              setClientes(data.clientes || [])
              setTotalPages(data.totalPages || 0);
              return data
            },
          )
          return []
        }}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <ClienteEmpresa cliente={selectedCliente} handleCloseModal={handleCloseModal} tableUpdateTriggerPai={tableUpdateTriggerPai} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default TableCliente;
