import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, IconButton, TextField, Tooltip, Typography, Modal, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlPoint } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import UsersService from '../../services/UsersService';
import { changeNotify } from '../../store/actions/notify.actions';
import { useDebounce } from '../../hooks/UseDebounce';
import { Button } from '../../components/Button/style';
import { modalStyleTipoContrato } from '../../themes/stylesGlobal';
import { NumericFormat } from 'react-number-format';

const schema = yup.object({
    cliente_id: yup.string().required(),
    inicio_contrato: yup.string().required(),
    fim_contrato: yup.string().required(),
    tipo_documentacao_id: yup.string().required(),
    descricao: yup.string().required(),
});

function CadastroDocumento({ onCadastroConcluido }) {
    const dispatch = useDispatch();
    const { debounce } = useDebounce(300);
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({ resolver: yupResolver(schema) });
    const [openModalButton, setOpenModalButton] = useState(false);
    const [nomeTipo, setNomeTipo] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const [openEmpresa, setOpenEmpresa] = useState(false);
    const [optionsEmpresa, setOptionsEmpresa] = useState([]);
    const [inputValueEmpresa, setInputValueEmpresa] = useState('');
    const [loadingEmpresa, setLoadingEmpresa] = useState(false);
    const [selectedOptionEmpresa, setSelectedOptionEmpresa] = useState(null);

    const handleOpenModalButton = () => {
        setOpenModalButton(true);
    };

    const handleCloseModalButton = () => {
        setOpenModalButton(false);
    };

    const handleSalvarTipo = () => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        let data = {
            nome: nomeTipo
        };
        UsersService.create(data, 'salvar/tipo/documento').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            setOpenModalButton(false);
        });
    };

    const buscaDocumento = useCallback(async (query) => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: query }, 'trazer/tipo');
        setOptions(res.tipo || []);
        setLoading(false);
    }, []);

    const buscaEmpresa = useCallback(async (query) => {
        setLoadingEmpresa(true);
        const res = await UsersService.getPagination({ search: query }, 'cliente');
        setOptionsEmpresa(res.clientes || []);
        setLoadingEmpresa(false);
    }, []);

    useEffect(() => {
        debounce(() => {
            if (inputValue.trim() !== '') {
                buscaDocumento(inputValue);
            } else {
                setOptions([]);
            }
        });
    }, [inputValue, debounce, buscaDocumento]);

    useEffect(() => {
        if (open) {
            buscaDocumento('');
        } else {
            setOptions([]);
        }
    }, [open, buscaDocumento]);

    useEffect(() => {
        debounce(() => {
            if (inputValueEmpresa.trim() !== '') {
                buscaEmpresa(inputValueEmpresa);
            } else {
                setOptionsEmpresa([]);
            }
        });
    }, [inputValueEmpresa, debounce, buscaEmpresa]);

    useEffect(() => {
        if (openEmpresa) {
            buscaEmpresa('');
        } else {
            setOptionsEmpresa([]);
        }
    }, [openEmpresa, buscaEmpresa]);

    const onSubmitForm = (data) => {
       
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        UsersService.create(data, 'contrato').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            onCadastroConcluido();
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };

    return (
        <Box bgcolor='#e0e0e0'>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box display='flex'>
                            <Autocomplete
                                fullWidth
                                open={openEmpresa}
                                onOpen={() => setOpenEmpresa(true)}
                                onClose={() => setOpenEmpresa(false)}
                                getOptionLabel={(option) => option.nome_razao_social}
                                options={optionsEmpresa}
                                loading={loadingEmpresa}
                                inputValue={inputValueEmpresa}
                                onInputChange={(event, newInputValue) => setInputValueEmpresa(newInputValue)}
                                value={selectedOptionEmpresa}
                                onChange={(event, newValue) => {
                                    setSelectedOptionEmpresa(newValue);
                                    setValue("cliente_id", newValue ? newValue.id : '');
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Empresa"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingEmpresa ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Inicio Contrato'
                            type='date'
                            variant='outlined'
                            fullWidth
                            size='small'
                            InputLabelProps={{ shrink: true, }}
                            {...register("inicio_contrato")}
                        />
                        <Typography variant='subtitle2'>{errors?.inicio_contrato?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label='Fim Contrato'
                            type='date'
                            variant='outlined'
                            fullWidth
                            size='small'
                            InputLabelProps={{ shrink: true, }}
                            {...register("fim_contrato")}
                        />
                        <Typography variant='subtitle2'>{errors?.fim_contrato?.message}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box display='flex' alignItems='center' marginTop='-3px'>
                            <Autocomplete
                                fullWidth
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                getOptionLabel={(option) => option.nome}
                                options={options}
                                loading={loading}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                value={selectedOption}
                                onChange={(event, newValue) => {
                                    setSelectedOption(newValue);
                                    setValue("tipo_documentacao_id", newValue ? newValue.id : '');
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de Contrato"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Tooltip title="Adicionar tipo de documento">
                                <IconButton onClick={handleOpenModalButton}>
                                    <ControlPoint color="success" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            label='Descrição'
                            type='text'
                            variant='outlined'
                            fullWidth
                            size='small'
                            multiline                           
                            inputProps={{ style: { resize: 'vertical' } }}
                            {...register("descricao")}
                        />
                        <Typography variant='subtitle2'>{errors?.descricao?.message}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={3} >
                    <NumericFormat
                       
                        allowLeadingZeros={false}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        allowedDecimalSeparators={["."]}
                        prefix="R$ "
                        thousandSeparator="."
                        customInput={TextField}
                        size="small"
                        label="Valor"
                        isAllowed={(values) => {
                            if (values.value.length > 9) return false;
                            return true;
                        }}
                        {...register("valor")}
                        onValueChange={(values) => {                            
                            setValue('valor', values.value); // atualiza o valor no formulário
                        }}
                    />
                    <Typography variant='subtitle2' color="error">{errors?.valor?.message}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <Button type='submit'>Salvar</Button>
                    </Grid>
                </Grid>
            </form>

            <Modal
                open={openModalButton}
                onClose={handleCloseModalButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyleTipoContrato}>
                    <Box>
                        <TextField
                            label='Tipo'
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={nomeTipo}
                            onChange={(e) => setNomeTipo(e.target.value)}
                        />
                        <Box marginTop={2}>
                            <Button onClick={handleSalvarTipo}>Salvar</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default CadastroDocumento;
