import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function SelectMes({ value, onChange }) {
  const meses = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' },
  ];

  return (
    <FormControl fullWidth>
      <InputLabel>Mês</InputLabel>
      <Select
        value={value}
        onChange={onChange}
       size='small'
      
      >
        {meses.map(mes => (
          <MenuItem key={mes.value} value={mes.value}>
            {mes.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectMes;
