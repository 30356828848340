import styled from "styled-components";

const darken = (color, amount) => {
  let colorCode = color.slice(1); // Remove the #
  const num = parseInt(colorCode, 16);
  let r = (num >> 16) - amount;
  let g = ((num >> 8) & 0x00ff) - amount;
  let b = (num & 0x0000ff) - amount;
  r = r < 0 ? 0 : r;
  g = g < 0 ? 0 : g;
  b = b < 0 ? 0 : b;
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
};

export const Button = styled.button`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || "#1565c0"};
  color: ${({ color }) => color || "white"};
  padding: 5px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ bgColor }) =>
      bgColor ? darken(bgColor, 20) : "#0d47a1"};
  }

  &:active {
    background-color: ${({ bgColor }) =>
      bgColor ? darken(bgColor, 40) : "#03dac6"};
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;
