import React from 'react';
import { Box, Grid, Typography, FormControl, InputLabel, MenuItem, Select, IconButton, Paper } from '@mui/material';

import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import UsersService from '../../services/UsersService';
import { changeNotify } from '../../store/actions/notify.actions';
import { SelectAutoComplete } from '../../components';
import CustomButton from '../../components/Button/CustomButon';


const schema = yup.object({
  nome_colaborador: yup.object().required('Nome do colaborador é obrigatório').nullable(),
  status: yup.string().required('Status é obrigatório'),
  empresa: yup.object().required('Empresa é obrigatória').nullable(),
});

function CadastroIntegracao({ handleCloseModal, tableUpdateTriggerPai }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });


 

  const onSubmitForm = async (data) => {
    dispatch(changeloading({ open: true, msg: 'Salvando..' }));

    const payload = {      
      funcionario_id: data.nome_colaborador?.id,    
      cliente_id: data.empresa.id,
      status: data.status
  };

    try {
      const res = await UsersService.create(payload, 'integracao');
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));   
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.message|| error.response.data.error }));
    }
  };






  return (
    <Box component={Paper} padding={3} elevation={4}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl fullWidth>
                <SelectAutoComplete
                  apiUrl="funcionario"
                  label="Nome Colaborador"
                  onChange={(newValue) => setValue('nome_colaborador', newValue)}
                />
                <Typography variant='subtitle2' color="error">{errors?.nome_colaborador?.message}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl fullWidth>
                <SelectAutoComplete
                  apiUrl="cliente"
                  label="Empresa"
                  onChange={(newValue) => setValue('empresa', newValue)}
                />
                <Typography variant='subtitle2' color="error">{errors?.empresa?.message}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  variant='outlined'
                  size='small'
                  {...register('status')}
                >
                  <MenuItem value="contratado">Contratado</MenuItem>
                  <MenuItem value="demitido">Demitido</MenuItem>
                </Select>
                <Typography variant='subtitle2' color="error">{errors?.status?.message}</Typography>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={2}>
              <CustomButton type='submit'>Salvar</CustomButton>
            </Grid>
         
        </Grid>
      </form>
    </Box>
  );
}
export default CadastroIntegracao;