import { Http } from "../api";

class UsersService {
  static async create(obj, route) {
    let res = null;
    await Http.post(`/${route}`, obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async get(route) {
    let res = null;
    await Http.get(`/${route}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async buscaExcel(filters, route) {
    try {
      const response = await Http.get(`/${route}`, { params: { ...filters } });
      return response.data; 
    } catch (error) {
     alert("Erro ao buscar dados para Excel:", error);
      throw error;
    }
  }

  static async getPesquisa(data, route) {
    let res = null;
     await Http.get(`/${route}`, { params: { ...data } }).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getById(id,route) {
    let res = null;
    await Http.get(`${route}/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getPagination(filters, route, page = 1, pageSize = 10) {
    let res = null;
    const params = {
      ...filters,
      page,
      pageSize,
    };

    try {
      const response = await Http.get(`/${route}`, { params });
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }

  static async update(id, data, route) {
    let res = null;

    await Http.put(`${route}/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async patch(id, data, route) {
    let res = null;

    await Http.patch(`${route}/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async Delete(id, route) {
    let res = null;
    await Http.delete(`${route}/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getId(id, route) {
    let res = null;
    await Http.get(`${route}/${id}`).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async buscarFotoPerfil(id) {
    let res = null;
    await Http.get(`buscarfotoperfil/${id}`).then((response) => {
      res = response.data;     
    });
    return res;
  }
}


export default UsersService;
