import React, { useState } from 'react';
import { Box,  Grid,  Modal, Paper,  TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from 'react-redux';
import { MaskNome } from '../../utils/mascaras';
import CustomButton from '../../components/Button/CustomButon';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';
import UsersService from '../../services/UsersService';


const schema = yup.object({
  name: yup.string().required().min(3), 
  name_user: yup.string().required().min(3), 
  password: yup.string().required(),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #002',
  boxShadow: 24,
  p: 4,
};


function AddUsruario() {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 

  function handleSubmit(data) {

    dispatch(changeloading({ open: true, msg: 'Enviando..' }))
    UsersService.create(data, 'usuarios').then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
    })
      .catch((error) => {
        console.log(error)
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.message }))
      })
  }
  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={1}  >
      <Grid container spacing={2} justifyContent="center">

        <Grid item xs={7} sm={6} md={4} >
          <Typography variant='h1' fontSize='35px!important'>Usuarios</Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={3}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <CustomButton variant='contained'
            fullWidth size='small'
            onClick={handleOpen}
            startIcon={<Add />}

          > Usuarios
          </CustomButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form onSubmit={onSubmit(handleSubmit)}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label='Nome'
                      variant='outlined'
                      fullWidth
                      size='small'
                      {...register("name")}
                      onInput={(e) => {
                        e.target.value = MaskNome(e.target.value);
                        setValue("name", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.name?.message}</Typography>
                  </Grid>                

                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label='Email'
                      type='email'
                      variant='outlined'
                       size='small'
                      fullWidth
                      {...register("email")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label='Nome para o login'
                      variant='outlined'
                      fullWidth
                      size='small'
                      {...register("name_user")}
                      onInput={(e) => {
                        e.target.value = MaskNome(e.target.value);
                        setValue("name_user", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.name?.message}</Typography>
                  </Grid>   

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label='Senha'
                      type='Password'
                      variant='outlined'
                      fullWidth
                       size='small'
                      {...register("password")}
                    />
                    <Typography variant='subtitle2'>{errors?.password?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={1} lg={4}>
                  </Grid>

                  <Grid item xs={12} sm={5} md={5} lg={3}>
                    <CustomButton type='submit' fullWidth variant='contained'>Enviar</CustomButton>
                  </Grid>

                  <Grid item xs={12} sm={6} md={5} lg={3}>
                  </Grid>

                </Grid>
              </form>
            </Box>
          </Modal>
        </Grid>

      </Grid>
    </Box>
  );
}

export default AddUsruario;