import { createTheme } from "@mui/material";
import { indigo, grey, red } from "@mui/material/colors";

const Light = createTheme({
  palette: {
    primary: {
      main: indigo[600],
      light: indigo[100],
      dark: indigo[900],
      contrastText: "#fff",
    },
    secondary: {
      main: grey[500],
      light: grey[200],
      dark: grey[900],
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontSize: 16,

    h1: {
      fontWeight: 700,
      fontSize: "48px !important",
      color: grey[800],
    },
    h2: {
      fontWeight: 600,
      fontSize: "45px !important",
      color: grey[100],
    },
    h3: {
      fontWeight: 600,
      fontSize: "30px!important",
      color: grey[700],
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.6rem",
      color: grey[700],
    },
    h5: {
      fontWeight: 600,
      fontSize: "15px!important",
      color: grey[700],
    },
    h6: {
      fontWeight: 500,
      fontSize: "2.2rem",
      color: grey[700],
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.2rem",
    },
    body2: {
      fontWeight: 400,
    },
    caption: {
      fontWeight: 400,
      fontSize: "1.2rem",
      color: grey[200],
    },
    subtitle1: {
      fontWeight: 200,
      fontSize: "1.6rem",
      color: grey[200],
    },
    subtitle2: {
      fontWeight: 200,
      fontSize: "0.8rem",
      color: red[500],
    },
  },

  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px", // Ajuste o tamanho da fonte conforme necessário
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#F0F7F9", // Define o fundo como branco
          borderRadius: 8,
         // height: '35px',          
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px", // Diminuir o tamanho do texto do label         
          top: "-3px", // Centraliza verticalmente
         
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px", // Diminuir o tamanho do texto dentro do TextField         
        },
        input: {
          fontSize: "14px", // Diminuir o tamanho do texto dentro do TextField         
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: "#F0F7F9", // Mesmo fundo do TextField
          borderRadius: 8,
          
        },       
        icon: {
          fontSize: "14px", // Ajustar o tamanho do ícone se necessário
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px", // Diminuir o tamanho do texto nas opções do Select
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          background: "#F0F7F9", // Mesmo fundo do TextField
          borderRadius: 8,
        
        },
        inputRoot: {
          fontSize: "14px", // Diminuir o tamanho do texto dentro do Autocomplete
        },
        endAdornment: {
          fontSize: "14px", // Ajustar o tamanho do ícone se necessário
        },
        option: {
          fontSize: "14px", // Diminuir o tamanho do texto nas opções do Autocomplete
        },
        paper: {
          borderRadius: 8, // Ajustar o border radius do menu de opções
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: indigo[500], // Cor de fundo ao passar o mouse
            color: '#fff', // Cor do texto ao passar o mouse
          },
        },
      },
    },
  },
});

export default Light;
