import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { StyledCheckboxContainer } from './styles';
import { Checkbox } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function StatusCheckbox({ checked, onChange, ...props }) {
  return (
    <Tooltip
      title={checked ? "Inativar?" : "Ativar?"}
      placement="top"
      style={{ fontSize: '20px !important' }}
    >
      <StyledCheckboxContainer>
        <Checkbox {...label} checked={checked} onChange={onChange} {...props} />
      </StyledCheckboxContainer>
    </Tooltip>
  );
}
