import {
    Home,
    AppRegistration,
    ContentCopy,
    Note,
    Build,
    LocalHospital,
    Settings,
  } from "@mui/icons-material";
  
  export const iconMap = {
    Home: Home,
    AppRegistration: AppRegistration,
    ContentCopy: ContentCopy,
    Note: Note,
    Build: Build,
    LocalHospital: LocalHospital,
    Settings: Settings,
  };
  