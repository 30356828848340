import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {getLastUpdateTime} from '../../../utils/helpers/dateUtils'
import CardDashboard from '../../../components/Cards/CardDashboard';
import UsersService from '../../../services/UsersService';



function CardsContratos() {
    const [totalContratoAtivo, setTotalContratoAtivo] = useState('');
    const [totalContratoAlerta, setTotalContratoAlerta] = useState('');
    const [totalContratoInativo, setTotalContratoInativo] = useState('');
    const [totalContratoVencido, setTotalContratoVencido] = useState('');
    const [lastUpdateTime, setLastUpdateTime] = useState(null);

 

    useEffect(() => {
        const updateTime = getLastUpdateTime();
        setLastUpdateTime(updateTime);
    }, []);


    const buscarContratos = async () => {
        await UsersService.get('buscar/contratos').then((res) => {
            console.log(res)
            setTotalContratoAtivo(res.total_ativo)
            setTotalContratoInativo(res.total_inativo)
            setTotalContratoAlerta(res.total_alerta)
            setTotalContratoVencido(res.total_vencido)
        }).catch((error) => {
            alert('erro ao buscar documentação! acione o suporte!')
        })
    }

    useEffect(() => {       
        buscarContratos();
    }, [])

    return (
        <>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Contratos Ativos "
                    color="#00b0ff"
                    colorText="#ffffff"
                    icon='contrato'
                    number={totalContratoAtivo}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/contratos'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Contratos em Alerta "
                    color="#b39ddb"
                    colorText="#ffffff"
                    icon='contrato'
                    number={totalContratoAlerta}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/contratos/alerta'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Contratos Vencidos "
                    color="#ff3d00"
                    colorText="#ffffff"
                    icon='contrato'
                    number={totalContratoVencido}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/contratos/vencido'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Contratos Inativos "
                    color="#212121"
                    colorText="#ffffff"
                    icon='contrato'
                    number={totalContratoInativo}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to={`/contratos/inativo`}
                />
            </Grid>
        </>
    )
}

export default CardsContratos;