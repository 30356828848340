
import { Box, Grid } from '@mui/material';
import { CardsContratos, CardsDocumentos, CardsTreinamentos } from './components';


function Dashboard() {


  return (
    <Box>
      <Grid container spacing={2}>
        <CardsDocumentos/>
        <CardsContratos/>    
        <CardsTreinamentos/>    
      </Grid>
    </Box>
  );
}

export default Dashboard;
