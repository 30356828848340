import React from "react";
import { Container, StyledLink, StyledIcon } from "./style";

import DescriptionIcon from '@mui/icons-material/Description';
import AlarmIcon from '@mui/icons-material/Alarm';
import Documento from '../../image/contrato.svg'; // Importe o SVG aqui
import Treinamento from '../../image/treinamento.svg'


function CardDashboard({ color, title, number, footerLabel, icon, to, colorText }) {
  // Mapeia o nome do ícone para o componente de ícone correspondente do Material-UI
  const iconMapping = {
    'document': <DescriptionIcon sx={{fontSize:'100px!important'}} />,
    'alarm': <AlarmIcon />,   
    'contrato': <img src={Documento} alt="Contrato" style={{ width: 100, height: 'auto', filter: 'invert(1)', }} />, // Renderize o SVG como uma imagem
    'treinamento': <img src={Treinamento} alt="treinamento" style={{ width: 100, height: 'auto', filter: 'invert(1)', }} />, // Renderize o SVG como uma imagem
    // Adicione outros mapeamentos de ícones conforme necessário
  };

  return (
    <StyledLink to={to}>
      <Container color={color} textColor={colorText} iconColor="red">
        <span>{title}</span>
        <h1>{number}</h1>
        <small>{footerLabel}</small>
        <StyledIcon borderColor="red">{iconMapping[icon]}</StyledIcon> 
      </Container>
    </StyledLink>
  );
}

export default CardDashboard;
