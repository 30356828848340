import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const StyledIcon = styled.div`

  position: absolute;
  top: 50%; /* Posicionado no centro verticalmente */
  right: -10px; /* Posicionado no lado direito */
  transform: translateY(-50%); /* Correção para centralizar verticalmente */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  border-radius: 50%;
  
`;


export const Container = styled.div`
  width: 88%;
  height: 130px;
  cursor: pointer; 
  background-color: ${(props) => props.color};
  border-radius: 7px;
  padding: 10px 20px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  color: ${(props) => props.textColor};

  > img {
    height: 110%;
    position: absolute;
    top: -10px;
    right: -30px;
    opacity: 0.5;
    border: 2px solid ;
    border-radius: 50%;   
  }

  > span {
    font-size: 18px;
    font-weight: 500;   
  }

  > small {
    font-size: 14px;
    position: absolute;
    bottom: 6px;
  }

  > h1 {
    margin-left: 35px;
  }

  @media (max-width: 770px) {
    > span {
      font-size: 14px;
    }

    > h1 {
      word-wrap: break-word;
      font-size: 22px;

      strong {
        display: inline-block;
        width: 100%;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 420px) {
    width: 100%;

    > h1 {
      display: flex;

      strong {
        position: initial;
        width: auto;
        font-size: 22px;
      }

      strong:after {
        display: inline-block;
        content: "";
        width: 1px;
      }
    }
  }
`;
