import React from 'react';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';

function SelectStatus({ value, onChange }) {
    // Lista de status disponíveis
    const statusOptions = [
        { value: 'vencido', label: 'Vencido' },
        { value: 'alerta', label: 'Alerta' },
        { value: 'ativo', label: 'Ativo' },
    ];

    return (
        <FormControl fullWidth >
            <InputLabel>Status</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                size='small'

            >
                {statusOptions.map(status => (
                    <MenuItem key={status.value} value={status.value}>
                        {status.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectStatus;
