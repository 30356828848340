import { Box, Modal, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import CadastroDocumento from './CadastroDocumento';

import TableDocumentacao from './TableDocumentacao';
import CustomButton from '../../components/Button/CustomButon';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function ControleDocumentacao() {
  const [openModal, setOpenModal] = useState(false);
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);


  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCadastroConcluido = () => {    
    setTableUpdateTrigger(prev => prev + 1);
    handleCloseModal();
  };

  return (
    <Box>
      <Box component={Paper} padding={1} display='flex'  alignItems='center' justifyContent='space-between'>
        <Box>
          <Typography variant='h3'>Documentação</Typography>
        </Box>
        <Box width='250px'>
          <CustomButton onClick={handleOpenModal}>Adicionar Documento</CustomButton>
        </Box>
      </Box>
      <Box>
        <TableDocumentacao tableUpdateTriggerPai={tableUpdateTrigger}/>
      </Box>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"      >
        <Box sx={modalStyle}>
          <Box>
            <CadastroDocumento  handleCloseModal={handleCloseModal} onCadastroConcluido={handleCadastroConcluido}/>
          </Box>
        </Box>
      </Modal>

    </Box>
  );
}

export default ControleDocumentacao;