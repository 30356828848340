import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardDashboard from '../../../components/Cards/CardDashboard';
import {getLastUpdateTime} from '../../../utils/helpers/dateUtils'
import UsersService from '../../../services/UsersService';



function CardsDocumentos() {
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [totalAlerta, setTotalAlerta] = useState('');
    const [totalAtivo, setTotalAtivo] = useState('');
    const [totalVencido, setTotalVencido] = useState('');
    const [totalInativo, setTotalInativo] = useState('');



    useEffect(() => {
        const updateTime = getLastUpdateTime();
        setLastUpdateTime(updateTime);
    }, []);

    const buscarDocumentacao = async () => {
        await UsersService.get('todos/documentos').then((res) => {
            setTotalAlerta(res.total_alerta)
            setTotalAtivo(res.total_ativo)
            setTotalVencido(res.total_vencido)
            setTotalInativo(res.total_inativo)
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        buscarDocumentacao();
    }, [])

    return (
        <>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Documentos  ativos"
                    color="#217e44"
                    colorText="#ffffff"
                    icon="document"
                    number={totalAtivo}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/documentacao/ativo'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Documentos em alerta"
                    color="#eeea12"
                    colorText="#444141"
                    icon="document"
                    number={totalAlerta}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/documentacao/alerta'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Documentos  vencido"
                    color="#c44d4d"
                    colorText="#ffffff"
                    icon="document"
                    number={totalVencido}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/documentacao/vencido'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                <CardDashboard
                    title="Documentos inativos"
                    color="#8d8787"
                    colorText="#ffffff"
                    icon="document"
                    number={totalInativo}
                    footerLabel={`última atualização às ${lastUpdateTime}`}
                    to='/documentacao/inativo'
                />
            </Grid>
        </>

    )
}

export default CardsDocumentos;